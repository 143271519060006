/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.BannerAdss.unshift(item)
  },
  SET_BannerAds(state, BannerAdss) {
    debugger;
    try {
   
      state.BannerAdss = BannerAdss;
      
    } catch (error) {
      console.error("Failed to parse BannerAds data JSON:", error);
      state.BannerAdss = [];
    }
  },
  UPDATE_BannerAds(state, BannerAds) {
    debugger
    const BannerAdsIndex = state.BannerAdss.findIndex((p) => p.ID == BannerAds.Id)
    Object.assign(state.BannerAdss[BannerAdsIndex], BannerAds)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.BannerAdss.findIndex((p) => p.ID == itemId)
      state.BannerAdss.splice(ItemIndex, 1)
  },


  //Attachment

  ADD_BannerAttachment(state, item) {
    state.BannerAttachments.unshift(item)
  },
  SET_BannerAttachment(state, BannerAttachments) {
    debugger;
    try {
   
      state.BannerAttachments = BannerAttachments;
      
    } catch (error) {
      console.error("Failed to parse BannerAttachment data JSON:", error);
      state.BannerAttachments = [];
    }
  },
  UPDATE_BannerAttachment(state, BannerAttachment) {
    debugger
    const BannerAttachmentIndex = state.BannerAttachments.findIndex((p) => p.ID == BannerAttachment.Id)
    Object.assign(state.BannerAttachments[BannerAttachmentIndex], BannerAttachment)
  },
  REMOVE_BannerAttachment(state, itemId) {
      const ItemIndex = state.BannerAttachments.findIndex((p) => p.ID == itemId)
      state.BannerAttachments.splice(ItemIndex, 1)
  },
}
