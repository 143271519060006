/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/BannerAds/Add", item,{headers:Token})
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/BannerAds/Index",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_BannerAds', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItemAds({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.put("API/BannerAds/Update", item,{headers:Token})
        .then((response) => {
          commit('UPDATE_BannerAds', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit }, itemid) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/BannerAds/GetByID?BannerAdsId=" + itemid,{headers:Token})
        .then((response) => {
          commit('UPDATE_BannerAds', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.delete("API/BannerAds/Delete?BannerAdsId="+item.Id,{headers:Token})
        .then((response) => {
          commit('REMOVE_ITEM', item.Id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UploadNewImage(context, file) {
    debugger;
    const formData = new FormData();
    formData.append("file",file, file.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //Attachmen

  addItemBannerAttachments({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/BannerAttachment/Add", item,{headers:Token})
        .then((response) => {
          commit('ADD_BannerAttachment', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItemsBannerAttachments({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/BannerAttachment/Index",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_BannerAttachment', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItemAttachments({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.put("API/BannerAttachment/Update", item,{headers:Token})
        .then((response) => {
          commit('UPDATE_BannerAttachments', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByIDBannerAttachments({ commit }, itemid) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/BannerAttachment/GetByID?BannerAttachmentsId=" + itemid,{headers:Token})
        .then((response) => {
          commit('UPDATE_BannerAttachments', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItemBannerAttachments({ commit }, item) {
    debugger;
    var Token = { Authorization: 'Bearer ' + localStorage.accessToken };
    return new Promise((resolve, reject) => {
      axios.delete("API/BannerAttachment/Delete?Id=" + item, { headers: Token })
        .then((response) => {
          commit('REMOVE_BannerAttachment', item);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
}
