<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- Loop through the Banners array -->
    <vx-card
      v-for="(banner, bannerIndex) in Banners"
      :key="bannerIndex"
      :ref="'filterCard-' + bannerIndex"
      :title=" $t(banner.Name) || 'Unnamed Banner'"
      class="user-list-filters mb-8 p-4"
      collapse-action
    >
      <div class="vx-row">
        <!-- Loop through BannerAttachments for each banner -->
      <!-- Image card taking 25% of the width -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
  <vx-card ref="imageCard" :title="$t('Example')" class="mb-8 p-4">
    <img :src="baseURL + '/' + banner.Img" alt="Image Example" class="w-full" style=" height: 300px;" />
  </vx-card>
</div>


        <!-- Input fields and Submit button for adding a new attachment -->
        <div class="vx-col md:w-3/4 sm:w-1/2 w-full mb-4">
          <div class="flex items-center p-6 space-x-4">
            <!-- URL Input -->
            <div class="flex-1">
              <label style="font-weight: bold; color: black;">{{ $t('Url') }}</label>
              <vs-input v-model="newUrl[bannerIndex]" class="mt-2 w-full" name="NameAr" />
            </div>

            <!-- File Input -->
            <div class="flex-1">
              <label style="font-weight: bold; color: black;">{{ $t('Image') }}</label>
              <vs-input
                type="file"
                @change="(event) => AttachmentUploaded(event, banner.Id)"
                accept="AllFiles/*"
                name="ImagePath"
              />
            </div>

            <!-- Submit Button -->
            <div>
              <vs-button v-if="checkPermission(اضافه) || CheckUser()" class="mt-6 rounded-sm" color="warning" @click="addNewData(banner.Id, bannerIndex)">
                {{ $t('Submit') }}
              </vs-button>
            </div>
          </div>

          <!-- Attachment Table -->
          <vs-table :data="banner.BannerAttachments">
            <template slot="thead">
              <vs-th style="font-size: 16px; font-family: almarai; color: green;">{{ $t('Url') }}</vs-th>
              <vs-th style="font-size: 16px; font-family: almarai; color: green;">{{ $t('Image') }}</vs-th>
              <vs-th style="font-size: 16px; font-family: almarai; color: crimson;">{{ $t('Action') }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <tr v-for="(item, index) in data" :key="index">
                  <vs-td>{{ item.Url }}</vs-td>
                  <vs-td><img :src="baseURL + item.ImagePath" alt="slide" style="width:100px; height:100px" /></vs-td>
                  <vs-td class="whitespace-no-wrap">
                    <feather-icon
                    v-if="checkPermission(حذف) || CheckUser()"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 hover:text-danger stroke-current ml-2"
                      @click="deleteData(item.Id)"
                    />
                  </vs-td>
                </tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>
    </vx-card>
  </div>
</template>


<script>
import { domain } from "@/gloabelConstant.js";
import moduleBannerAds from "@/store/BannerAds/moduleBannerAds.js";

export default {
  data() {
    return {
      baseURL: domain,
      Banners: [], // To store the banners data
      newUrl: [], // To store the new URLs for each banner
      newImagePath: [], // To store the new image paths for each banner
    };
  },
  methods: {
    // Handle file upload for a specific banner
    AttachmentUploaded(event, bannerId) {
      const file = event.target.files[0];
      if (file) {
        this.$vs.loading();
        this.$store
          .dispatch("BannerAttachmentsList/UploadNewImage", file)
          .then((res) => {
            if (res.status === 200) {
              // Map the returned image path to the corresponding banner index
              const bannerIndex = this.Banners.findIndex(b => b.Id === bannerId);
              this.newImagePath[bannerIndex] = res.data;
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },
    CheckUser() {
      debugger;
          if (typeof localStorage !== 'undefined') {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
              return JSON.parse(userInfo).UserType === "SuperAdmin";
            }
          }
          return false; // Default return if localStorage or userInfo is not available
        },
      checkPermission(OPName) {
    const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];
    
    // Ensure Page and Page.Name are not null or undefined
    return screenRolesPermissions.some(permission => 
      permission.Operation && permission.Operation.Name === OPName
    );
  },
 
    // Add new data for a specific banner
    addNewData(bannerId, bannerIndex) {
      const newAttachment = {
        BannerId: bannerId,  // Add BannerId to the new attachment
        ImagePath: this.newImagePath[bannerIndex],
        Url: this.newUrl[bannerIndex],
      };
      this.$store
        .dispatch("BannerAttachmentsList/addItemBannerAttachments", newAttachment)
        .then(() => {
          this.$store.dispatch("BannerAttachmentsList/fetchDataListItemsBannerAttachments");
          this.newImagePath[bannerIndex] = '';
          this.newUrl[bannerIndex] = '';
          location.reload();
          window.showSuccess();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // Delete an attachment
    deleteData(id) {
      this.$vs.loading();
      this.$store
        .dispatch("BannerAttachmentsList/removeItemBannerAttachments", id)
        .then(() => {
          this.$store.dispatch("BannerAttachmentsList/fetchDataListItemsBannerAttachments");
          location.reload();
          window.showSuccess();
        })
        .catch((err) => {
          console.error(err);
          window.showError();
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleBannerAds.isRegistered) {
      this.$store.registerModule("BannerAttachmentsList", moduleBannerAds);
      moduleBannerAds.isRegistered = true;
    }
    // Fetch banners and their attachments
    this.$store.dispatch("BannerAttachmentsList/fetchDataListItems")
      .then((res) => {
        this.Banners = res.data.data; // Assuming response structure
      });
  },
};
</script>


<style lang="scss">
#data-list-list-view {
  .vx-row {
    display: flex;
    flex-wrap: wrap;
  }
  .vx-col {
    padding: 0.5rem;
  }
  .vs-button {
    width: 100%;
  }
  .flex {
    display: flex;
  }
  .space-x-4 > * + * {
    margin-left: 1rem;
  }
}
</style>
